export type CSS = CSSEntry[];
export type CSSEntry = [key: string, value: string | number | CSS];

export function formatCSS(entries: CSS): string {
  return entries.map(formatEntry).join("").replace(/;$/, "");
}

function formatEntry([key, value]: CSSEntry): string {
  return typeof value === "string" || typeof value === "number"
    ? `${key}:${value};`
    : `${key}{${formatCSS(value)}}`;
}
