import "../index.css";

import { animafont, Letter } from "@animafont/core";
import { Font, parse } from "opentype.js";

import ttf from "../../../../assets/fonts/Handlee-Regular.ttf";
import json from "../../cli/json/Handlee-Regular.json?url";

async function loadTTF() {
  const res = await fetch(ttf);
  const data = await res.arrayBuffer();
  return parse(data);
}

async function loadJSON() {
  const res = await fetch(json);
  const data = await res.json();
  return new Map(
    (data as Letter[]).map((letter) => [letter.codePoint, letter]),
  );
}

type Animafont = { font: Font; letters: Map<number, Letter> };

let animafontData: Animafont | null = null;
let callback: ((animafont: Animafont) => void) | null = null;

Promise.all([loadTTF(), loadJSON()])
  .then(([font, letters]) => {
    animafontData = { font, letters };

    if (callback) {
      callback(animafontData);
      callback = null;
    }
  })
  .catch((error) => console.error(error));

function withAnimafont(cb: (animafont: Animafont) => void) {
  if (animafontData) {
    cb(animafontData);
  } else {
    callback = cb;
  }
}

const formEl = document.getElementById("form") as HTMLFormElement;
const textEl = document.getElementById("text") as HTMLTextAreaElement;
const fontSizeEl = document.getElementById("font-size") as HTMLInputElement;

formEl.addEventListener("submit", (e) => {
  e.preventDefault();

  const text = textEl.value;
  const fontSize = Number(fontSizeEl.value);

  if (!text) {
    return;
  }

  withAnimafont(({ font, letters }) => {
    const svg = animafont(text, {
      font,
      letters,
      fontSize,
      avgDrawPxPerMs: 0.4,
      avgTravelPxPerMs: 1,
      initialDelayMs: 0,
    });

    const div = document.getElementById("svg-container")!;

    div.innerHTML = svg;
  });
});
