import type { Font, Path } from "opentype.js";
import { INode, stringify } from "svgson";

import { formatCSS } from "./css";
import { computeLayout } from "./opentype-layout";
import * as svg from "./svg-utils";

export type Point = { x: number; y: number };

export type Stroke = {
  stroke: string;
  start: Point;
  end: Point;
  length: number;
  width: number;
  linecap?: string;
  linejoin?: string;
  clip: string;
};

export type Letter = {
  codePoint: number;
  filename: string;
  sha1: string;
  width: number;
  height: number;
  strokes: Stroke[];
};

export type AnimafontConfig = {
  font: Font;
  letters: Map<number, Letter>;
  fontSize: number;
  textColor?: string;
  bgColor?: string;
  avgDrawPxPerMs?: number;
  avgTravelPxPerMs?: number;
  extraLetterDelayMs?: number;
  initialDelayMs?: number;
  minDrawMs?: number;
  drawBoxes?: boolean;
  multiline?: boolean;
  group?: boolean;
};

export function animafont(text: string, config: AnimafontConfig): string {
  const {
    font,
    letters,
    fontSize,
    textColor = "#000",
    bgColor,
    avgDrawPxPerMs = 1,
    avgTravelPxPerMs = 2,
    extraLetterDelayMs = 50,
    initialDelayMs = 50,
    minDrawMs = 50,
    drawBoxes = false,
    multiline = true,
    group = false,
  } = config;

  const scale = fontSize / 1000;

  const { glyphs, x1, y1, width, height } = multiline
    ? multiLineLayout(font, fontSize, text)
    : singleLineLayout(font, fontSize, text);

  const widthStr = formatNum(width);
  const heightStr = formatNum(height);

  const x1Str = formatNum(x1);
  const y1Str = formatNum(y1);

  const clipCache = new Map<string, string>();
  const defs: INode[] = [];
  const paths: INode[] = [];

  if (bgColor) {
    paths.push(
      svg.rect({
        fill: bgColor,
        x: x1Str,
        y: y1Str,
        width: widthStr,
        height: heightStr,
      }),
    );
  }

  let lastEnd: null | Point = null;
  let delayMs = initialDelayMs;

  for (const glyph of glyphs) {
    const { x1, x2, y1, y2 } = glyph;

    if (x1 === x2 && y1 === y2) {
      continue;
    }

    const codePoint = text.charCodeAt(glyph.index);
    const letter = letters.get(codePoint);

    if (drawBoxes) {
      paths.push(
        svg.path({
          fill: "none",
          stroke: letter ? "green" : "red",
          d: `M ${x1} ${y1} H ${x2} V ${y2} H ${x1} Z`,
        }),
      );
    }

    if (!letter) {
      if (glyph.path) {
        const d = compressPathData(glyph.path.toPathData(6));
        paths.push(svg.path({ fill: "gray", d }));
      }
      continue;
    }

    const strokePaths: INode[] = [];

    for (const stroke of letter.strokes) {
      let clipId = clipCache.get(stroke.clip);

      if (!clipId) {
        clipId = `c${clipCache.size}`;
        clipCache.set(stroke.clip, clipId);

        defs.push(svg.clipPath({ id: clipId }, [svg.path({ d: stroke.clip })]));
      }

      const strokeStart = project(stroke.start, letter, glyph);

      delayMs += computeDelay(lastEnd, strokeStart, avgTravelPxPerMs);

      const durationMillis = Math.max(
        minDrawMs,
        Math.round((stroke.length * scale) / avgDrawPxPerMs),
      );

      strokePaths.push(
        svg.path({
          class: "s",
          d: stroke.stroke,
          "stroke-width": stroke.width.toString(),
          "stroke-linecap": stroke.linecap,
          "stroke-linejoin": stroke.linejoin,
          "clip-path": `url(#${clipId})`,
          pathLength: "0.99",
          style: `animation-duration:${durationMillis}ms;animation-delay:${delayMs}ms`,
        }),
      );

      delayMs += durationMillis;
      lastEnd = project(stroke.end, letter, glyph);
    }

    const transform = `translate(${formatNum(x1)} ${formatNum(y1)})scale(${formatNum(scale)})`;

    if (group) {
      paths.push(svg.g({ transform }, strokePaths));
    } else {
      for (const path of strokePaths) {
        path.attributes.transform = transform;
      }
      paths.push(...strokePaths);
    }

    delayMs += extraLetterDelayMs;
  }

  const css = formatCSS([
    ["@keyframes draw", [["to", [["stroke-dashoffset", 0]]]]],
    [
      ".s",
      [
        ["stroke", textColor],
        ["fill", "none"],
        ["stroke-dasharray", 1],
        ["stroke-dashoffset", 1],
        ["stroke-linecap", "round"],
        ["stroke-linejoin", "round"],
        ["animation-name", "draw"],
        ["animation-timing-function", "cubic-bezier(0.4,0,.2,1)"],
        ["animation-fill-mode", "forwards"],
      ],
    ],
  ]);

  return stringify(
    svg.svg(
      {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: `${x1Str} ${y1Str} ${widthStr} ${heightStr}`,
        width: widthStr,
        height: heightStr,
      },
      [svg.style(css), svg.defs(defs), ...paths],
    ),
  );
}

// === Layout =================================================================

type Layout = {
  x1: number;
  y1: number;
  width: number;
  height: number;
  glyphs: {
    index: number;
    x1: number;
    x2: number;
    y1: number;
    y2: number;
    path: Path;
  }[];
};

function singleLineLayout(font: Font, fontSize: number, text: string): Layout {
  const glyphs = font.getPaths(text, 0, 0, fontSize).map((path, index) => {
    const { x1, x2, y1, y2 } = path.getBoundingBox();
    return { index, x1, x2, y1, y2, path };
  });

  const x1 = Math.min(...glyphs.map((bbox) => bbox.x1));
  const x2 = Math.max(...glyphs.map((bbox) => bbox.x2));
  const y1 = Math.min(...glyphs.map((bbox) => bbox.y1));
  const y2 = Math.max(...glyphs.map((bbox) => bbox.y2));

  const width = x2 - x1;
  const height = y2 - y1;

  return { glyphs, x1, y1, width, height };
}

function multiLineLayout(font: Font, fontSize: number, text: string): Layout {
  const layout = computeLayout(font, text);
  const scale = fontSize / 1000;

  return {
    glyphs: layout.glyphs.map((g) => {
      const [x, y] = g.position;
      const bbox = g.data.getBoundingBox();

      return {
        index: g.index,
        x1: scale * (x + bbox.x1),
        x2: scale * (x + bbox.x2),
        y1: -scale * (y + bbox.y2),
        y2: -scale * (y + bbox.y1),
        get path() {
          return g.data.getPath(scale * x, -scale * y, fontSize);
        },
      };
    }),
    x1: 0,
    y1: 0,
    width: scale * layout.width,
    height: scale * layout.height,
  };
}

// === Utilities ==============================================================

function computeDelay(a: Point | null, b: Point, avgTravelPxPerMs: number) {
  if (a === null) {
    return 0;
  }

  return Math.round(
    Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2) / avgTravelPxPerMs,
  );
}

function project(
  point: Point,
  letter: Letter,
  bbox: { x1: number; x2: number; y1: number; y2: number },
): Point {
  return {
    x: (point.x / letter.width) * (bbox.x2 - bbox.x1) + bbox.x1,
    y: (point.y / letter.height) * (bbox.y2 - bbox.y1) + bbox.y1,
  };
}

function formatNum(num: number): string {
  return compressNum(num.toFixed(3));
}

function compressNum(num: string): string {
  return num.replace(/(?<=\d)\.?0+$/, "").replace(/^0(?=\.)/g, "");
}

function compressPathData(d: string): string {
  return d.replace(/[\d\.]+/g, compressNum);
}
