import type * as opentype from "opentype.js";

import { computeLines, Line, Mode } from "./word-wrap";

// A default 'line-height' according to Chrome/FF/Safari (Jun 2016)
const DEFAULT_LINE_HEIGHT = 1.175;

export type Options = {
  /** The width of the box in font units; will cause word-wrapping if defined. */
  width?: number;
  /** Alignment of the text within its width (default `left`). */
  align?: "left" | "center" | "right";
  /** The additional letter spacing in font units (default 0). */
  letterSpacing?: number;
  /**
   * The line height in font units as per CSS spec.
   * Default to `1.175 * font.unitsPerEm` to match browsers.
   */
  lineHeight?: number;
  /** The starting character index into text to layout (default 0). */
  start?: number;
  /** The ending (exclusive) index into text to layout (default `text.length`). */
  end?: number;
  /**
   * Can be `pre` (maintain spacing) or `nowrap` (collapse whitespace but only break
   * on newline characters). If undefined, defaults to normal word-wrap behaviour.
   */
  mode?: Mode;
};

export type Glyph = {
  /** Position in raw font units. */
  position: [x: number, y: number];
  data: opentype.Glyph;
  index: number;
  column: number;
  row: number;
};

export type Layout = {
  glyphs: Glyph[];
  lines: Line[];

  /**
   * The distance from the pen origin to the baseline of the last line of text.
   */
  baseline: number;

  /**
   * The `L` value in the CSS line-height spec
   * (https://www.w3.org/TR/CSS2/visudet.html#line-height).
   * Divide this by two for the "half-leading", which tells you how far above
   * the first ascender and below the last descender the text box extends to.
   */
  leading: number;

  /**
   * The computed `lineHeight` in font units. If no `lineHeight` was specified
   * in the options, it will be equivalent to `1.175 * font.unitsPerEm`.
   */
  lineHeight: number;

  /**
   * The distance from the left of the text box to the widest line of text in
   * the box. (If `align` is `left`, this value will be 0.)
   */
  left: number;

  /**
   * The distance from the right of the text box to the widest line of text in
   * the box. (If `align` is `right`, this value will be 0.)
   */
  right: number;

  /**
   * The width of the text box. Equal to `options.width` if it was defined,
   * or `maxLineWidth` (the length of a single line of text) otherwise.
   */
  width: number;

  /**
   * The height of the text box, including the half leadings above the first
   * ascender and below the last descender.
   */
  height: number;

  maxLineWidth: number;
};

export function computeLayout(
  font: opentype.Font,
  text: string,
  options: Options = {},
): Layout {
  const {
    align = "left",
    letterSpacing = 0,
    width = Infinity,
    lineHeight = font.unitsPerEm * DEFAULT_LINE_HEIGHT, // in em units
  } = options;

  const lines = computeLines(text, {
    ...options,
    measure: (text, start, end, width) =>
      computeMetrics(font, text, start, end, width, letterSpacing),
  });

  const maxLineWidth = Math.max(...lines.map((line) => line.width));

  // As per CSS spec https://www.w3.org/TR/CSS2/visudet.html#line-height
  const AD = Math.abs(font.ascender - font.descender);
  const L = lineHeight - AD;

  // Y position is based on CSS line height calculation
  let x = 0;
  let y = -font.ascender - L / 2;
  const totalHeight = (AD + L) * lines.length;
  const preferredWidth = isFinite(width) ? width : maxLineWidth;
  const glyphs: Glyph[] = [];
  let lastGlyph = null;

  // Layout by line
  for (let lineIndex = 0; lineIndex < lines.length; lineIndex++) {
    const line = lines[lineIndex];
    const start = line.start;
    const end = line.end;
    const lineWidth = line.width;

    // Layout by glyph
    for (let j = start, c = 0; j < end; j++, c++) {
      const char = text.charAt(j);
      const glyph = getGlyph(font, char);

      // TODO:
      // Align center & right are off by a couple pixels, need to revisit.
      if (j === start && align === "right") {
        x -= glyph.leftSideBearing!;
      }

      // Apply kerning
      if (lastGlyph) {
        x += font.getKerningValue(glyph, lastGlyph) || 0;
      }

      // Align text
      let tx = 0;

      if (align === "center") {
        tx = (preferredWidth - lineWidth) / 2;
      } else if (align === "right") {
        tx = preferredWidth - lineWidth;
      }

      // Store glyph data
      glyphs.push({
        position: [x + tx, y],
        data: glyph,
        index: j,
        column: c,
        row: lineIndex,
      });

      // Advance forward
      x += letterSpacing + getAdvance(glyph, char);
      lastGlyph = glyph;
    }

    // Advance down
    y -= lineHeight;
    x = 0;
  }

  // Compute left & right values
  let left = 0;

  if (align === "center") {
    left = (preferredWidth - maxLineWidth) / 2;
  } else if (align === "right") {
    left = preferredWidth - maxLineWidth;
  }

  const right = Math.max(0, preferredWidth - maxLineWidth - left);

  return {
    glyphs,
    baseline: L / 2 + Math.abs(font.descender),
    leading: L,
    lines,
    lineHeight,
    left,
    right,
    maxLineWidth,
    width: preferredWidth,
    height: totalHeight,
  };
}

function getRightSideBearing(glyph: opentype.Glyph) {
  const glyphWidth = (glyph.xMax || 0) - (glyph.xMin || 0);
  const rsb = glyph.advanceWidth! - glyph.leftSideBearing! - glyphWidth;
  return rsb;
}

function computeMetrics(
  font: opentype.Font,
  text: string,
  start: number,
  end: number,
  width = Infinity,
  letterSpacing = 0,
) {
  start = Math.max(0, start ?? 0);
  end = Math.min(end ?? text.length, text.length);

  let pen = 0;
  let count = 0;
  let curWidth = 0;

  for (let i = start; i < end; i++) {
    const char = text.charAt(i);

    // Tab is treated as multiple space characters
    const glyph = getGlyph(font, char);
    ensureMetrics(glyph);

    // determine kern value to next glyph
    let kerning = 0;
    if (i < end - 1) {
      const nextGlyph = getGlyph(font, text.charAt(i + 1));
      kerning += font.getKerningValue(glyph, nextGlyph);
    }

    // determine if the new pen or width is above our limit
    const xMax = glyph.xMax || 0;
    const xMin = glyph.xMin || 0;
    const glyphWidth = xMax - xMin;
    const rsb = getRightSideBearing(glyph);
    const newWidth = pen + glyph.leftSideBearing! + glyphWidth + rsb;
    if (newWidth > width) {
      break;
    }

    pen += letterSpacing + getAdvance(glyph, char) + kerning;
    curWidth = newWidth;
    count++;
  }

  return {
    start: start,
    end: start + count,
    width: curWidth,
  };
}

function getGlyph(font: opentype.Font, char: string) {
  const isTab = char === "\t";
  return font.charToGlyph(isTab ? " " : char);
}

function getAdvance(glyph: opentype.Glyph, _char: string) {
  // TODO: handle tab gracefully
  return glyph.advanceWidth!;
}

function ensureMetrics(glyph: opentype.Glyph) {
  // Opentype.js only builds its paths when the getter is accessed
  // so we force it here.
  return glyph.path;
}
