const newline = /\n/;
const newlineChar = "\n";
const whitespace = /\s/;

export type Line = { start: number; end: number; width: number };

export type Measure = (
  text: string,
  start: number,
  end: number,
  width: number,
) => Line;

export type Mode = "nowrap" | "pre";

export type Config = {
  measure: Measure;
  width: number;
  start: number;
  end: number;
  mode?: Mode;
};

export function wordWrap(text: string, options: Partial<Config> = {}): string {
  return computeLines(text, options)
    .map((line) => text.substring(line.start, line.end))
    .join("\n");
}

export function computeLines(
  text: string,
  options: Partial<Config> = {},
): Line[] {
  // zero width results in nothing visible
  if (options.width === 0 && options.mode !== "nowrap") {
    return [];
  }

  const config: Config = {
    measure: options.measure ?? monospace,
    width: options.width ?? Number.MAX_VALUE,
    start: Math.max(0, options.start || 0),
    end: options.end ?? text.length,
    mode: options.mode,
  };

  return (config.mode === "pre" ? pre : greedy)(text, config);
}

function idxOf(text: string, chr: string, start: number, end: number) {
  const idx = text.indexOf(chr, start);

  if (idx === -1 || idx > end) {
    return end;
  }

  return idx;
}

function pre(text: string, { measure, start, end, width }: Config): Line[] {
  const lines: Line[] = [];
  let lineStart = start;

  for (let i = start; i < end && i < text.length; i++) {
    const chr = text.charAt(i);
    const isNewline = newline.test(chr);

    // If we've reached a newline, then step down a line
    // Or if we've reached the EOF
    if (isNewline || i === end - 1) {
      const lineEnd = isNewline ? i : i + 1;
      const measured = measure(text, lineStart, lineEnd, width);
      lines.push(measured);

      lineStart = i + 1;
    }
  }
  return lines;
}

function greedy(text: string, { measure, start, end, width, mode }: Config) {
  // A greedy word wrapper based on LibGDX algorithm
  // https://github.com/libgdx/libgdx/blob/master/gdx/src/com/badlogic/gdx/graphics/g2d/BitmapFontCache.java
  const lines: Line[] = [];

  let testWidth = width;

  // if 'nowrap' is specified, we only wrap on newline chars
  if (mode === "nowrap") {
    testWidth = Number.MAX_VALUE;
  }

  while (start < end && start < text.length) {
    // get next newline position
    const newLine = idxOf(text, newlineChar, start, end);

    // eat whitespace at start of line
    while (start < newLine) {
      if (!whitespace.test(text.charAt(start))) {
        break;
      }
      start++;
    }

    // determine visible # of glyphs for the available width
    const measured = measure(text, start, newLine, testWidth);

    let lineEnd = start + (measured.end - measured.start);
    let nextStart = lineEnd + newlineChar.length;

    // if we had to cut the line before the next newline...
    if (lineEnd < newLine) {
      // find char to break on
      while (lineEnd > start) {
        if (whitespace.test(text.charAt(lineEnd))) {
          break;
        }
        lineEnd--;
      }
      if (lineEnd === start) {
        if (nextStart > start + newlineChar.length) {
          nextStart--;
        }
        lineEnd = nextStart; // If no characters to break, show all.
      } else {
        nextStart = lineEnd;
        // eat whitespace at end of line
        while (lineEnd > start) {
          if (!whitespace.test(text.charAt(lineEnd - newlineChar.length))) {
            break;
          }
          lineEnd--;
        }
      }
    }
    if (lineEnd >= start) {
      const result = measure(text, start, lineEnd, testWidth);
      lines.push(result);
    }
    start = nextStart;
  }
  return lines;
}

const monospace: Measure = (_, start, end, fullWidth) => {
  // TODO: is this the right semantics?
  const width = Math.min(fullWidth, end - start);

  return {
    start: start,
    end: start + width,
    width,
  };
};
