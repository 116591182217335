import type { INode } from "svgson";

type Attributes = Record<string, string | undefined>;

function filterAttributes(attributes: Attributes): Record<string, string> {
  return Object.fromEntries(
    Object.entries(attributes).filter((entry) => entry[1] !== undefined),
  ) as Record<string, string>;
}

function elementFactory(name: string) {
  return element;

  function element(attributes: Attributes, children?: INode[]): INode;
  function element(children?: INode[]): INode;
  function element(...args: any[]): INode {
    const attributes =
      args.length > 0 && !Array.isArray(args[0])
        ? filterAttributes(args.shift() as Attributes)
        : {};

    const children: INode[] = args[0] ?? [];

    return { name, type: "element", value: "", attributes, children };
  }
}

export const svg = elementFactory("svg");
export const defs = elementFactory("defs");
export const clipPath = elementFactory("clipPath");
export const g = elementFactory("g");
export const path = elementFactory("path");
export const rect = elementFactory("rect");

export function style(css: string, attributes?: Attributes) {
  return {
    name: "style",
    type: "element",
    value: "",
    attributes: attributes ? filterAttributes(attributes) : {},
    children: [text(css)],
  };
}

export function text(text: string): INode {
  return {
    name: "",
    type: "text",
    value: text,
    attributes: {},
    children: [],
  };
}
